<template>
    <div class="container px-0">
        <div class="row">
            <div class="col-12 col-md-9">
                <div class="row">
                    <div class="col-12 col-md-8">
                        <input
                            v-model="searchForm.name"
                            type="text"
                            class="form-control"
                            placeholder="Enter program name"
                        />
                    </div>
                    <div class="col-12 col-md-4 mt-2 mt-md-0">
                        <select
                            v-model="searchForm.country"
                            class="form-select"
                        >
                            <option value="">Select a Country</option>
                            <option
                                v-for="country in countries"
                                :value="country.label"
                                :key="country.value"
                            >
                                {{ country.label }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 d-md-flex mt-2 mt-md-3">
                        <!--<select
                            v-model="searchForm.school_type"
                            class="form-select"
                        >
                            <option value="">School type</option>
                            <option value="University">Universities</option>
                            <option value="College">Colleges</option>
                        </select>-->
                        <select
                            v-model="searchForm.level"
                            class="form-select mt-2 mt-md-0"
                        >
                            <option value="">Level</option>
                            <!-- <option value="undergraduate">Undergraduate</option>
                            <option value="postgraduate">Postgraduate</option> -->
                            <option
                                v-for="(prg, index) in program_list"
                                :key="index"
                                :value="prg.id"
                            >
                                {{ prg.name }}
                            </option>
                        </select>
                        <!-- <select
                            v-model="searchForm.tuition_fee"
                            class="form-select ms-0 ms-md-2 mt-2 mt-md-0"
                        >
                            <option value="">Tuition fee</option>
                            <option value="10k-15k">10,000 to 15,000</option>
                            <option value="15k-20k">15,000 to 20,000</option>
                        </select> -->
                        <select
                            v-model="searchForm.application_fee"
                            class="form-select ms-0 ms-md-2 mt-2 mt-md-0"
                        >
                            <option value="">Application fee</option>
                            <option value="0">0</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value=">100">More than 100</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-3 mt-3 mt-md-0">
                <div class="d-flex flex-md-column">
                    <div>
                        <button
                            type="button"
                            class="btn primaryBtn"
                            @click="searchPrograms(true)"
                        >
                            Search
                        </button>
                    </div>
                    <div class="ms-3 ms-md-0 mt-2">
                        <a
                            href="#"
                            class="text-decoration-none text-secondary"
                            @click.prevent="clearSearch"
                            >Clear all</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex mt-4">
        <h6 class="mb-0 me-5">Programs</h6>
        <router-link
            :to="{ name: 'GuestSchoolList' }"
            class="primaryColor text-decoration-none"
        >
            <h6>Schools</h6>
        </router-link>
    </div>
    <div class="mt-4">
        <div v-if="programs.length == 0">
            <div class="mt-1 fw-bold">No Program Found</div>
        </div>
        <div v-if="programs.length > 0" class="row">
            <empty-fragment v-for="(prg, index) in programs"
                :key="index"
                >
                <program-card :prg="prg"/>
            </empty-fragment>

            <div class="mt-3" v-if="nextPage">
                <button class="btn primaryBtn" @click="searchPrograms()">Load More Results</button>
            </div>
        </div>
    </div>
</template>
<script>
import EmptyFragment from '@/components/EmptyFragment.vue'
import ProgramCard from '@/components/ProgramCard.vue'
import { mapActions } from 'vuex';
import ApiService from '../apiServices/api.service';
import { Serialize, toCurrency, markUpApplicationFee, formatProgramDuration } from '@/utils/constants';
const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export default {
    name: 'ProgramList',
    components: {
        ProgramCard,
        EmptyFragment
    },
    data: function () {
        return {
            programs: [],
            filter: {},
            searchForm: {
                name: '',
                country: '',
                school_type: '',
                level: '',
                tuition_fee: '',
                application_fee: '',
            },
            showMore: {},
            maxLimit: 10,
            program_list: [
                {name: 'Bachelors', id: 'Bachelor'},
                {name: 'Diploma', id: 'Diploma'},
                {name: 'Masters', id: 'Master'},
                {name: 'MBA', id: 'MBA'},
                {name: 'Certificate', id: 'Certificat'},
                {name: 'Dual Degree', id: 'Dual Degree'},
                {name: 'PhD', id: 'PhD'},
            ],
            nextPage: null,
            delayLoading: false
        };
    },
    methods: {
        ...mapActions({
            updateModal: 'general/updateInfo',
        }),
        toCurrency,
        markUpApplicationFee,
        clearSearch() {
            this.searchForm = {
                name: '',
                country: '',
                school_type: '',
                level: '',
                tuition_fee: '',
                application_fee: '',
                page: ''
            };
            this.nextPage = null;
            this.delayLoading = false;
            this.programs = [];
            this.searchPrograms();
        },
        cleanQueries(opt) {
            Object.keys(opt).forEach(key => {
                if (opt[key] == null || opt[key] == "") {
                    delete opt[key];
                }
            });
            return opt;
        },
        getFromQueries() {
            let query = this.$route.query;
            Object.keys(this.searchForm).forEach(key => {
                if (query[key] != null && query[key] != "") {
                    this.searchForm[key] = query[key];
                }
            });
        },
        searchPrograms(resetSearch = false) {
            if(resetSearch) {
                this.searchForm.page = '';
                this.nextPage = null;
                this.programs = [];
            }
            if(this.nextPage != null) {
                this.searchForm.page = this.nextPage;
            }
            this.filter = this.searchForm; //this.$parent.$parent.searchForm
            let query = Object.assign({}, this.filter);
            let newQuery = this.cleanQueries(query);
            this.$router.replace({ query: newQuery });
            let qry = Serialize(this.filter);
            qry = qry !== '' ? '?' + qry : '';
            ApiService.getData('edu/findPrograms' + qry)
                .then((resp) => {
                    if (resp && resp.data) {
                        this.programs = this.programs.concat(resp.data.programs);
                        this.nextPage = resp.data.nextPage == null ? null : resp.data.nextPage.toString();
                        this.delayLoading = false;
                    }
                })
        },
        registerPageScroll() {
            // detect scroll to bottom
            if ((window.innerHeight + Math.round(window.scrollY)) >= (document.body.offsetHeight * 0.95)) {
                // you're at the bottom of the page
                if(this.nextPage && this.delayLoading == false) {
                    this.delayLoading = true;
                    this.searchPrograms()
                }  
            }
        },
        slicePrograms(listOfPrograms) {
            return listOfPrograms.slice(0, this.length);
        },
        viewMorePrograms(numOfPrograms) {
            if (this.length > numOfPrograms) return;
            this.length = this.length + 10;
        },
        formatProgramDuration(months) {
            return formatProgramDuration(months);
        }
    },
    created() {
        // this.$parent.$on('search', this.search)
    },
    unmounted () {
        window.removeEventListener('scroll', this.registerPageScroll);
    },
    mounted() {
        /*if (this.$store.state.user.currentUser !== null && this.$route.name != "ProgramList") {
        this.$router.push({ name: 'ProgramList' })
        return
    }
    if (this.$store.state.admin.currentUser !== null && this.$route.name != "AdminProgramList") {
        this.$router.push({ name: 'AdminProgramList' })
        return
    }*/
        window.addEventListener("scroll", this.registerPageScroll);
        this.getFromQueries();
        this.searchPrograms();
        this.emitter.on('re-search', (searchData) => {
            this.filter = searchData;
            this.searchPrograms();
        });
    },
    computed: {
        currentUser() {
            let currUser = null;
            if (this.$store.state.user.currentUser !== null) {
                currUser = this.$store.state.user.currentUser;
            }
            if (this.$store.state.admin.currentUser !== null) {
                currUser = this.$store.state.admin.currentUser;
            }
            if (this.$store.state.scout.currentUser !== null) {
                currUser = this.$store.scout.admin.currentUser;
            }
            return currUser;
        },
        countries() {
            const list = countries.getNames('en', { select: 'official' });
            return Object.keys(list).map((key) => ({
                value: key,
                label: list[key],
            }));
        },
        // loadedPrograms() {
        //   return this.schools
        // }
    },
};
</script>

