<template>
    <div
        class="py-1 px-3 px-md-5 primaryBG d-flex justify-content-between align-items-center"
        v-if="currentUser == null"
    >
        <router-link to="/"
            ><img src="assets/images/schooliply_header_2.png" width="150"
        /></router-link>
        <h5>
            <router-link to="/students/login" class="text-white fw-bold"
                >Log in</router-link
            >
        </h5>
    </div>
    <div class="container py-3 px-3 py-md-5 px-md-5">
        <page-header
            header="Schools and Programs"
            subText="Find the next best place to study."
        />

        <router-view ref="childComp"/>
    </div>
</template>
<script>
import PageHeader from '@/components/PageHeader.vue';
// import SchoolList from '@/components/Schools.vue'
// import ProgramList from '@/components/Programs.vue'
import { Colors } from '@/utils/constants';

export default {
    name: 'FindProgram',
    components: {
        PageHeader,
    },
    data: function () {
        return {
            currentTab: 0,
        };
    },
    methods: {},
    computed: {
        cssProps() {
            return {
                '--primary-color': Colors.primaryColor,
                'overflow-x': 'auto',
            };
        },
        currentUser() {
            let currUser = null;
            if (this.$store.state.user.currentUser !== null) {
                currUser = this.$store.state.user.currentUser;
            }
            if (this.$store.state.admin.currentUser !== null) {
                currUser = this.$store.state.admin.currentUser;
            }
            if (this.$store.state.scout.currentUser !== null) {
                currUser = this.$store.state.scout.currentUser;
            }
            return currUser;
        },
    },
};
</script>
<style scoped>
.tabButtons a {
    text-decoration: none;
    margin-right: 50px;
    font-weight: 500;
    color: gray;
}
.tabButtons a.router-link-exact-active {
    color: var(--primary-color);
}
</style>
