import AuthService from '../apiServices/scout/auth.service'
import ScoutService from '../apiServices/scout/scout.service'
import router from '../router'

const user = JSON.parse(localStorage.getItem('SchooliplyScout'))
const initialState = user
  ? { status: { loggedIn: true }, currentUser: user, notificationCount: 0 }
  : { status: {}, currentUser: null }

export const scoutAuthStore = {
  namespaced: true,
  state: initialState,
  actions: {
    login ({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user)
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    forgotPWD ({ commit }, user) {
      return AuthService.forgotPWD(user).then(
        user => {
          commit('registerSuccess', user)
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    resetPWD ({ commit }, user) {
      const token = user.token
      delete user.token
      return AuthService.resetPWD(user, token).then(
        user => {
          commit('registerSuccess', user)
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    logout ({ commit }) {
      commit('logout')
    },
    getNotificationCount ({ commit }) {
      return ScoutService.getData('get_notifications').then(
        resp => {
          commit('getNotificationCount', resp.data)
        }
      )
    },
    reduceNotificationCount ({ commit }) {
      commit('reduceNotificationCount')
    },
    acceptTerms ({ commit }) {
      commit('acceptTerms')
    },
  },
  mutations: {
    loginSuccess (state, user) {
      state.status = { loggedIn: true }
      state.currentUser = user.data
    },
    loginFailure (state) {
      state.status = {}
      state.currentUser = null
    },
    logout (state) {
      state.status = {}
      state.currentUser = null
      router.push('/scout/login')
    },
    registerSuccess (state) {
      state.status = {}
    },
    registerFailure (state) {
      state.status = {}
    },
    getNotificationCount (state, data) {
      state.notificationCount = data.filter(x => x.is_read != true).length;
    },
    reduceNotificationCount (state) {
      state.notificationCount--;
      state.notificationCount = state.notificationCount < 0 ? 0 : state.notificationCount;
    },
    acceptTerms (state) {
      state.currentUser.uuser.terms_conditions_accepted = true
    }
  }
}
