<template>
    <div class="px-0 min-vh-100 vh-100">
        <div
            class="d-flex flex-column align-items-center align-items-sm-start pt-4 text-white h-100 leftPanel"
            :class="toggleOpen"
            :style="{ background: primaryColor }"
        >
        <div v-if="toggleOpen == ''" class="openAction">
                <a @click="toggleOpenAction" role="button">
                    <i class="bi bi-list"></i>
                </a>
            </div>
            <div class="w-100 d-flex justify-content-end" v-else>
                <a @click="toggleOpenAction" role="button">
                    <i class="bi bi-list"></i>
                </a>
            </div>
            <div class="bigImage">
                <img
                    src="/assets/images/schooliply_header_2.png"
                    class="panelHeaderImage"
                />
            </div>
            <div class="smallImage">
                <img src="/assets/images/school_logo_only.png" class="" width="45" />
            </div>

            <div class="flex-grow-1 overflow-y-auto w-100 longMenu mt-5 mb-3">

            <ul
                class="nav nav-pills flex-column align-items-start w-100 menu"
            >
                <li class="nav-item">
                    <router-link :to="{ name: 'AdminIndex' }" class="dashboard"
                        ><i class="bi bi-house me-3"></i
                        ><span class="menu-link-text"
                            >Dashboard</span
                        ></router-link
                    >
                </li>
                <li
                    class="nav-item"
                    v-if="role == 'intermediate-admin' || role == 'super-admin'"
                >
                    <router-link to="/admin/institutions"
                        ><i class="bi bi-building me-3"></i
                        ><span class="menu-link-text"
                            >Institutions</span
                        ></router-link
                    >
                </li>

                <li
                    class="nav-item"
                    v-if="
                        role == 'media' ||
                        role == 'intermediate-admin' ||
                        role == 'super-admin'
                    "
                >
                    <router-link to="/admin/blogs"
                        ><i class="bi bi bi-newspaper me-3"></i
                        ><span class="menu-link-text"
                            >Blogs</span
                        ></router-link
                    >
                </li>
                <li class="nav-item">
                    <router-link :to="{ name: 'AdminProgramList' }"
                        ><i class="bi bi-search me-3"></i
                        ><span class="menu-link-text"
                            >Find Programs</span
                        ></router-link
                    >
                </li>
                <li
                    class="nav-item"
                    v-if="role == 'intermediate-admin' || role == 'super-admin'"
                >
                    <router-link to="/admin/admins"
                        ><i class="bi bi-people me-3"></i
                        ><span class="menu-link-text"
                            >Admins</span
                        ></router-link
                    >
                </li>
                <li class="nav-item" v-if="role == 'admin'">
                    <router-link :to="{ name: 'AssignedApplications' }"
                        ><i class="bi bi-folder me-3"></i
                        ><span class="menu-link-text"
                            >Applications</span
                        ></router-link
                    >
                </li>
                <li
                    class="nav-item"
                    v-if="role == 'intermediate-admin' || role == 'super-admin'"
                >
                    <router-link :to="{ name: 'AdminApplications' }"
                        ><i class="bi bi-folder me-3"></i
                        ><span class="menu-link-text"
                            >Applications</span
                        ></router-link
                    >
                </li>
                <li
                    class="nav-item"
                    v-if="role == 'super-admin'"
                >
                    <router-link :to="{ name: 'ScoutApplicationList' }"
                        ><i class="bi bi-ui-checks me-3"></i
                        ><span class="menu-link-text"
                            >Scout Requests</span
                        ></router-link
                    >
                </li>
            </ul>
            <ul
                class="nav nav-pills flex-column align-items-start menu secondMenu w-100"
            >
                <li class="nav-item">
                    <router-link :to="{ name: 'AdminProfile' }"
                        ><i class="bi bi-person me-3"></i
                        ><span class="menu-link-text"
                            >Profile</span
                        ></router-link
                    >
                </li>
                <li class="nav-item">
                    <router-link
                        class="d-flex"
                        :to="{ name: 'AdminNotification' }"
                    >
                        <div class="position-relative">
                            <i class="bi bi-bell me-3"></i>
                            <span
                                :class="
                                    this.notificationCount > 0
                                        ? 'd-block'
                                        : 'd-none'
                                "
                                class="noty-badge position-absolute translate-middle badge rounded-pill bg-danger"
                            >
                                {{ this.notificationCount }}
                                <span class="visually-hidden"
                                    >unread notifications</span
                                >
                            </span>
                        </div>
                        <span class="menu-link-text"
                            >Notifications</span
                        ></router-link
                    >
                </li>
            </ul>

        </div>

            <div class="signOut py-3">
                <a href="#" @click.prevent="showSignOut"
                    ><i class="bi bi-box-arrow-right me-3"></i
                    ><span class="menu-link-text">Sign Out</span></a
                >
            </div>
        </div>
    </div>
</template>

<script>
import { Colors } from '@/utils/constants.js';
import { mapActions } from 'vuex';
export default {
    name: 'LeftPanel',
    data: function () {
        return {
            primaryColor: Colors.primaryColor,
            white: Colors.white,
            toggleOpen: ''
        };
    },
    methods: {
        ...mapActions({
            logout: 'admin/logout',
            showSignOut: 'general/showSignOut',
            getNotyCount: 'admin/getNotificationCount',
        }),
        toggleOpenAction () {
            this.toggleOpen = this.toggleOpen == '' ? 'open' : '';
        }
    },
    mounted() {
        if(window.innerWidth > 768) {
            this.toggleOpen = 'open'
        }
        /*if(window.Tawk_API?.visitor == undefined) {
            let userObj = this.$store.state.admin.currentUser?.uuser;
            let name = `Admin - ${userObj?.first_name} ${userObj?.last_name}`
            window.Tawk_API?.setAttributes({
                'name'  : name,
                'email' : userObj.email
            }, function(){});
        }*/
        this.getNotyCount();
    },
    computed: {
        role() {
            return this.$store.state.admin.currentUser.uuser.role;
        },
        notificationCount() {
            return this.$store.state.admin.notificationCount;
        },
    },
};
</script>
<style scoped>
.leftPanel {
    padding-left: 25px;
    padding-right: 15px;
    z-index: 9;
}
.leftPanel.open {
    padding-left: 25px;
    padding-right: 25px;
    width: 215px;
}
.leftPanel a {
    color: white;
    text-decoration: none;
    font-weight: 300;
    display: flex;
}
.leftPanel a.router-link-exact-active,
.leftPanel a.router-link-active {
    font-weight: 700;
    border-left: 10px solid white;
    margin-left: -25px;
    padding-left: 15px;
}
.leftPanel .menu-link-text{
    display: none;
}
.leftPanel .bigImage, .leftPanel.open .smallImage{
    display: none;
}
.leftPanel.open .bigImage, .leftPanel .smallImage{
    display: block;
}
.leftPanel .smallImage{
    margin-left: -15px;
}
.leftPanel.open .menu-link-text{
    display: block;
}
.panelHeaderImage {
    width: 125px;
    margin-left: -10px;
}
.menu li {
    margin-bottom: 25px;
}
.secondMenu {
    border-top: 1px solid white;
    padding-top: 25px;
}
.longMenu {
    overflow-y: auto;
    overflow-x: hidden;
}
.noty-badge{
    top: -7px;
    left: 17px;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .leftPanel.open {
        position: fixed;
    }
    .openAction {
        margin-left: -15px;
    }
}
</style>
