<template>
  <div class="container-fluid px-0">
      <div class="d-flex">
        <LeftPanel v-if="currentUser != null"></LeftPanel>
        <div v-if="currentUser != null" class="flex-grow-1 px-0 overflow-auto">
          <div class="wrapper py-4 py-md-0">
            <router-view v-slot="{ Component, route }">
                <div :key="route.name">  
                  <component :is="Component"></component>
                </div>
            </router-view>
          </div>
        </div>
        <div v-if="currentUser == null" class="col-12 px-0">
          <div class="wrapper">
            <router-view v-slot="{ Component, route }">
                <div :key="route.name">  
                  <component :is="Component"></component>
                </div>
            </router-view>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import LeftPanel from '@/components/admin/LeftPanel.vue'

export default {
  name: 'AdminHome',
  components: {
    LeftPanel
  },
  data: function () {
    return {
      user: this.$store.state.admin.lastName,
      authenticated: this.$store.state.admin.isAutheticated,
      lists: [
        {
          id: 0,
          text: 'Hello'
        },
        {
          id: 1,
          text: 'World'
        }
      ]
    }
  },
  computed: {
    currentUser () {
      return this.$store.state.admin.currentUser
    }
  },
  mounted () {
    if (this.currentUser == null) {
      // console.log('redirected here')
      this.$router.push('/admin/login')
    }
  }
}
</script>
<style scoped>
.wrapper{
  height: 100vh;
  overflow-y: auto;
}
</style>
