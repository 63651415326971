import AuthService from '../apiServices/students/auth.service'
import UserService from '../apiServices/students/user.service'
import router from '../router'

const user = JSON.parse(localStorage.getItem('SchooliplyUser'))
const initialState = user
  ? { status: { loggedIn: true }, currentUser: user, notificationCount: 0 }
  : { status: {}, currentUser: null }

export const userAuthStore = {
  namespaced: true,
  state: initialState,
  actions: {
    savePersonalInfo ({ commit }, personalInfo) {
      if (personalInfo._id != null) {
        return UserService.putData('update_personalInfo/' + personalInfo._id, personalInfo).then(
          resp => {
            commit('savePersonalInfo', resp.data)
            return Promise.resolve(resp.data)
          },
          error => {
            return Promise.reject(error)
          }
        )
      }
      return UserService.postData('save_personalInfo', personalInfo).then(
        resp => {
          commit('savePersonalInfo', resp.data)
          return Promise.resolve(resp.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    saveEducationInfo ({ commit }, educationInfo) {
      if (educationInfo._id != null) {
        return UserService.putDataForm('update_educationalInfo/' + educationInfo._id, educationInfo).then(
          resp => {
            commit('saveEducationInfo', resp.data)
            return Promise.resolve(resp.data)
          },
          error => {
            return Promise.reject(error)
          }
        )
      }
      return UserService.postDataForm('save_educationalInfo', educationInfo).then(
        resp => {
          commit('saveEducationInfo', resp.data)
          return Promise.resolve(resp.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    saveBackgroundInfo ({ commit }, backgroundInfo) {
      if (backgroundInfo._id != null) {
        return UserService.putDataForm('update_backgroundInfo/' + backgroundInfo._id, backgroundInfo).then(
          resp => {
            commit('saveBackgroundInfo', resp.data)
            return Promise.resolve(resp.data)
          },
          error => {
            return Promise.reject(error)
          }
        )
      }
      return UserService.postDataForm('save_backgroundInfo', backgroundInfo).then(
        resp => {
          commit('saveBackgroundInfo', resp.data)
          return Promise.resolve(resp.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    saveNoBackgroundInfo ({ commit }) {
      return UserService.getData('noBackgroundInfo').then(
        resp => {
          const success = resp.data
          commit('saveNoBackgroundInfo', { success })
          return Promise.resolve(resp.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    saveExamScores ({ commit }, examScore) {
      if (examScore._id != null) {
        return UserService.putDataForm('update_examInfo/' + examScore._id, examScore).then(
          resp => {
            commit('saveExamScores', resp.data)
            return Promise.resolve(resp.data)
          },
          error => {
            return Promise.reject(error)
          }
        )
      }
      return UserService.postDataForm('save_examInfo', examScore).then(
        resp => {
          commit('saveExamScores', resp.data)
          return Promise.resolve(resp.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    deleteEducationInfo ({ commit }, id) {
      return UserService.deleteData('delete_educationalInfo/' + id).then(
        resp => {
          const success = resp.data
          commit('deleteEducationInfo', { success, id })
          return Promise.resolve(resp.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    deleteExamScore ({ commit }, id) {
      return UserService.deleteData('delete_examInfo/' + id).then(
        resp => {
          const success = resp.data
          commit('deleteExamScore', { success, id })
          return Promise.resolve(resp.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    deleteBackgroundInfo ({ commit }, id) {
      return UserService.deleteData('delete_backgroundInfo/' + id).then(
        resp => {
          const success = resp.data
          commit('deleteBackgroundInfo', { success, id })
          return Promise.resolve(resp.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    login ({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user)
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    forgotPWD ({ commit }, user) {
      return AuthService.forgotPWD(user).then(
        user => {
          commit('registerSuccess', user)
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    resetPWD ({ commit }, user) {
      const token = user.token
      delete user.token
      return AuthService.resetPWD(user, token).then(
        user => {
          commit('registerSuccess', user)
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    logout ({ commit }) {
      commit('logout')
    },
    register ({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          // commit('loginSuccess', response)
          return Promise.resolve(response)
        },
        error => {
          commit('registerFailure')
          return Promise.reject(error)
        }
      )
    },
    getAllDocuments ({ commit }) {
      return UserService.getData('get_all_documents').then(
        resp => {
          commit('getAllDocuments', resp.data)
          return Promise.resolve(resp.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    getNotificationCount ({ commit }) {
      return UserService.getData('get_notifications').then(
        resp => {
          commit('getNotificationCount', resp.data)
        }
      )
    },
    reduceNotificationCount ({ commit }) {
      commit('reduceNotificationCount')
    }
  },
  mutations: {
    savePersonalInfo (state, data) {
      state.currentUser.uuser.personal_info = data
    },
    deleteEducationInfo (state, { success, id }) {
      if (success) {
        const left = state.currentUser.uuser.educational_info.filter(x => x._id !== id)
        state.currentUser.uuser.educational_info = left
      }
    },
    saveEducationInfo (state, data) {
      if (state.currentUser.uuser.educational_info == null) {
        state.currentUser.uuser.educational_info = []
        state.currentUser.uuser.educational_info.push(data)
        return
      }
      const arr = state.currentUser.uuser.educational_info
      var ind = arr.findIndex((el) => el._id === data._id)
      if (ind === -1) {
        state.currentUser.uuser.educational_info.push(data)
      } else {
        state.currentUser.uuser.educational_info[ind] = data
      }
    },
    saveBackgroundInfo (state, data) {
      if (state.currentUser.uuser.background_info == null) {
        state.currentUser.uuser.background_info = []
        state.currentUser.uuser.background_info.push(data)
        return
      }
      state.currentUser.uuser.refused_visa_before = true
      const arr = state.currentUser.uuser.background_info
      var ind = arr.findIndex((el) => el._id === data._id)
      if (ind === -1) {
        state.currentUser.uuser.background_info.push(data)
      } else {
        state.currentUser.uuser.background_info[ind] = data
      }

      // set refused to no
      if (state.currentUser.uuser.background_info.length === 0) {
        state.currentUser.uuser.refused_visa_before = false
      }
    },
    saveNoBackgroundInfo (state, success) {
      if (success) {
        state.currentUser.uuser.refused_visa_before = false
      }
    },
    deleteBackgroundInfo (state, { success, id }) {
      if (success) {
        const left = state.currentUser.uuser.background_info.filter(x => x._id !== id)
        state.currentUser.uuser.background_info = left
      }
    },
    deleteExamScore (state, { success, id }) {
      if (success) {
        const left = state.currentUser.uuser.exam_info.filter(x => x._id !== id)
        state.currentUser.uuser.exam_info = left
      }
    },
    saveExamScores (state, data) {
      if (state.currentUser.uuser.exam_info == null) {
        state.currentUser.uuser.exam_info = []
        state.currentUser.uuser.exam_info.push(data)
        return
      }
      const arr = state.currentUser.uuser.exam_info
      var ind = arr.findIndex((el) => el._id === data._id)
      if (ind === -1) {
        state.currentUser.uuser.exam_info.push(data)
      } else {
        state.currentUser.uuser.exam_info[ind] = data
      }
    },
    getAllDocuments (state, data) {
      state.currentUser.uuser.documents = data
    },
    loginSuccess (state, user) {
      state.status = { loggedIn: true }
      if (user.data && user.data.uuser.personal_info && user.data.uuser.personal_info.D_O_B) {
        user.data.uuser.personal_info.D_O_B = user.data.uuser.personal_info.D_O_B.split('T')[0]
      }
      state.currentUser = user.data
    },
    loginFailure (state) {
      state.status = {}
      state.currentUser = null
    },
    logout (state) {
      state.status = {}
      state.currentUser = null
      router.push('/students/login')
    },
    registerSuccess (state) {
      state.status = {}
    },
    registerFailure (state) {
      state.status = {}
    },
    getNotificationCount (state, data) {
      state.notificationCount = data.filter(x => x.is_read != true).length;
    },
    reduceNotificationCount (state) {
      state.notificationCount--;
      state.notificationCount = state.notificationCount < 0 ? 0 : state.notificationCount;
    }
  }
}
