<template>
    <div
        class="py-1 px-3 px-md-5 primaryBG d-flex justify-content-between align-items-center"
        v-if="currentUser == null"
    >
        <router-link to="/"
            ><img src="assets/images/schooliply_header_2.png" width="150"
        /></router-link>
        <h5>
            <router-link to="/students/login" class="text-white fw-bold"
                >Log in</router-link
            >
        </h5>
    </div>
    <div v-if="program == null && !isLoading">No program found</div>
    <div v-if="program != null">
        <img src="assets/images/Best-Universities-In-The-World-edited.jpg" class="imageHeader" />
        <div class="d-flex">
            <div
                class="rounded-circle circleName"
                :style="program?.university?.logo == null ? 'background-image:url(' + 'assets/images/school_logo_only.png' + ')' : 'background-image:url(' + program?.university?.logo + ')'"
            ></div>
            <div class="ms-2 ms-md-5 mt-1 mt-md-3">
                <h5 class="mb-1">{{ program.university?.name }}</h5>
                <div class="fw-bold smaller" v-if="program.university?.address">
                    <span class="me-2">LOCATED AT</span>
                    <span class="text-muted text-uppercase">{{
                        program.university?.address
                    }}</span>
                </div>
            </div>
        </div>

        <div class="container py-3 px-3 py-md-5 px-md-5">
            <div class="pb-4 goBack">
                <a
                    href="#"
                    @click="$router.go(-1)"
                    class="text-decoration-none primaryColor"
                    ><i class="bi bi-arrow-left"></i> Go Back</a
                >
            </div>

            <div v-if="program">
                <div class="fw-bold mb-4">{{ program?.name }}</div>
                <div class="row ms-0">
                    <div class="col-12 col-md-9 ps-0">
                        <div class="mb-3">
                            <h6>Program Description</h6>
                            <div v-if="program.program_description"
                                v-html="program.program_description"
                                class="text-secondary"
                            ></div>

                            <div class="mt-3" v-if="program.application_fee && program.application_fee > 0">
                                <div class="fw-bold text-muted">
                                    Application fee is {{ program.currency_symbol + toCurrency(program.application_fee) }}, there is a 6.5% Schooliply processing fee added
                                </div>
                            </div>
                        </div>

                        <div v-if="program.admission_requirement" class="mb-3">
                            <h6>Admission Requirements</h6>
                            <div
                                v-html="program.admission_requirement"
                                class="text-secondary"
                            ></div>
                        </div>
                    </div>
                    <div
                        class="col-12 col-md-3 mt-3 mt-md-0 ps-0 ps-md-5 specs"
                    >
                        <div v-if="program.program_type" class="mb-4">
                            <div class="text-uppercase text-muted title">
                                Program Type
                            </div>
                            <div>{{ program.program_type }}</div>
                        </div>
                        <div v-if="program.duration_in_months" class="mb-4">
                            <div class="text-uppercase text-muted title">
                                Program Duration
                            </div>
                            <div>{{ formatProgramDuration(program.duration_in_months) }}</div>
                        </div>
                        <div v-if="program.application_fee" class="mb-4">
                            <div class="text-uppercase text-muted title">
                                Application Fee
                            </div>
                            <div>
                                {{ program.currency_symbol
                                }}{{ toCurrency(markUpApplicationFee(program.application_fee)) }}
                            </div>
                        </div>
                        <div v-if="program.tuition_fee" class="mb-4">
                            <div class="text-uppercase text-muted title">
                                Tuition Fee
                            </div>
                            <div>
                                {{ program.currency_symbol
                                }}{{ toCurrency(program.tuition_fee) }}
                            </div>
                        </div>
                        <div>
                            <h6>
                                <router-link
                                    class="text-decoration-none btn primaryBtn"
                                    :to="{
                                        name: 'GuestApplyPage',
                                        params: {
                                            id: '0',
                                            school_id: program.university._id,
                                            program_id: program._id,
                                        },
                                    }"
                                    >Apply</router-link
                                >
                            </h6>
                        </div>
                        <div class="mt-5">
                            <div class="text-uppercase text-muted title mb-1">
                                Sessions
                            </div>
                            <div class="card mb-3 p-2 small" v-for="sess in program.available_sessions" :key="sess._id">
                                <h6>{{ sess.name }}
                                    <span class="text-danger">
                                        {{
                                            checkCanApply(
                                                sess.application_deadline,
                                                program?.available_sessions.length
                                            )
                                                ? ' (Closed)'
                                                : ''
                                        }}
                                    </span>
                                </h6>
                                <div>Application Deadline: {{ sess.application_deadline }}</div>
                                <div>Resumption Date: {{ sess.resumption_date }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="program?.similarPrograms?.length > 0" class="mt-5">
                    <h6>Similar Programs</h6>
                    <div class="row">
                        <empty-fragment v-for="(prg, index) in program?.similarPrograms"
                            :key="index"
                            >
                            <program-card :prg="prg"/>
                        </empty-fragment>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProgramCard from '@/components/ProgramCard.vue'
import EmptyFragment from '@/components/EmptyFragment.vue'
import { mapActions } from 'vuex';
import ApiService from '../apiServices/api.service';
import { Colors, toCurrency, markUpApplicationFee, formatProgramDuration, checkCanApply } from '@/utils/constants';
export default {
    name: 'StudentProgram',
    components: {
        ProgramCard,
        EmptyFragment
    },
    data: function () {
        return {
            school: null,
            program: null,
            isLoading: false,
        };
    },
    methods: {
        ...mapActions({
            updateModal: 'general/updateInfo',
        }),
        toCurrency,
        markUpApplicationFee,
        checkCanApply,
        switchTabs(val) {
            this.currentTab = val;
        },
        fetchProgram() {
            this.isLoading = true;
            ApiService.getData(
                'edu/getProgram/school/' +
                    this.$route.params.school_id +
                    '/program/' +
                    this.$route.params.id
            )
                .then((resp) => {
                    this.isLoading = false;
                    if (resp && resp.data) {
                        this.program = resp.data;
                    }
                    document.querySelector(".wrapper").scrollTo(0, 0);
                })
                .catch((err) => {
                    this.isLoading = false;
                    console.log(err);
                });
        },
        formatProgramDuration(months) {
            return formatProgramDuration(months);
        }
    },
    mounted() {
        this.fetchProgram();
    },
    watch: {
        $route(from, to) {
            if(from.name == to.name && (from.params.school_id != to.params.school_id || from.params.id != to.params.id)) {
                this.fetchProgram()
            }
        },
    },
    computed: {
        cssProps() {
            return {
                '--primary-color': Colors.primaryColor,
                'overflow-x': 'auto',
            };
        },
        currentUser() {
            let currUser = null;
            if (this.$store.state.user.currentUser !== null) {
                currUser = this.$store.state.user.currentUser;
            }
            if (this.$store.state.admin.currentUser !== null) {
                currUser = this.$store.state.admin.currentUser;
            }
            if (this.$store.state.scout.currentUser !== null) {
                currUser = this.$store.state.scout.currentUser;
            }
            return currUser;
        },
    },
};
</script>
<style scoped>
.imageHeader {
    width: 100%;
}
.specs .title {
    font-size: 0.8em;
    font-weight: 500;
    color: #6a6a6a;
    letter-spacing: 0.12em;
}
.circleName {
    width: 11%;
    padding-top: 11%;
    border: 1px solid #c4c4c4;
    margin-left: 9.27%;
    margin-top: -3%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
}
</style>