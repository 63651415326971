<template>
    <div class="d-flex mb-4 flex-column col-12 col-md-6 myCard" v-if="prg.university != null">
        <div class="card h-100">
            <div class="card-header d-flex align-items-center ps-0">
                <div :class="prg?.university?.logo == null ? 'ps-2' : 'ps-3 pe-2'">
                    <img
                        :src="prg.university?.logo ?? 'assets/images/school_logo_only.png'"
                        class="rounded-circle"
                        width="45"
                        height="45"
                    />
                </div>
                <div class="ms-1">
                    <h6 class="mb-0">
                        <router-link
                            class="text-decoration-none primaryColor"
                            :to="{
                                name: 'ProgramPage',
                                params: {
                                    school_id: prg.university?._id,
                                    id: prg._id,
                                },
                                query: {name: prg.name + ' - ' + prg.university?.name}
                            }"
                            >{{ prg.name }}</router-link
                        >
                    </h6>
                    <div v-if="prg.university != null" class="small">
                        <div>
                            <router-link
                            class="text-decoration-none primaryColor"
                            :to="{
                                name: 'SchoolPage',
                                params: { id: prg.university?._id },
                                query: {name: prg.university?.name}
                            }"
                            >{{ prg.university?.name }}</router-link
                        >
                        </div>
                        <div v-if="prg.university?.country">{{ prg.university?.country }}</div>
                    </div>
                </div>
            </div>
            <div class="card-body d-flex flex-column">
                <div class="row small">
                    <div class="col-6 col-md-3 mb-3 mb-md-0">
                        <div class="title muted">
                            Application Fee
                        </div>
                        <div>
                            {{ prg.currency_symbol
                            }}{{ toCurrency(markUpApplicationFee(prg.application_fee)) }}
                        </div>
                    </div>
                    <div class="col-6 col-md-3 mb-3 mb-md-0" v-if="prg.tuition_fee">
                        <div class="title muted">Tuition Fee</div>
                        <div>
                            {{ prg.currency_symbol
                            }}{{ toCurrency(prg.tuition_fee) }}
                        </div>
                    </div>
                    <div class="col-6 col-md-3 mb-3 mb-md-0">
                        <div class="title muted">Program Type</div>
                        <div>{{ prg.program_type }}</div>
                    </div>
                    <div v-if="prg.duration_in_months" class="col-6 col-md-3 mb-3 mb-md-0">
                        <div class="title muted">Program Duration</div>
                        <div>{{ formatProgramDuration(prg.duration_in_months) }}</div>
                    </div>
                </div>
            </div>
            <div
                class="card-footer d-flex py-0"
            >
                <div class="flex-grow-1 text-center py-2 border-right-button">
                    <h6 class="mb-0">
                        <router-link
                            class="text-decoration-none primaryColor me-2"
                            :to="{
                                name: 'ProgramPage',
                                params: {
                                    school_id: prg.university?._id,
                                    id: prg._id,
                                },
                                query: {name: prg.name + ' - ' + prg.university?.name}
                            }"
                            >View</router-link
                        >
                    </h6>
                </div>

                <div class="flex-grow-1 text-center py-2">
                    <h6 class="mb-0">
                        <router-link
                            class="text-decoration-none primaryColor"
                            :to="{
                                name: 'GuestApplyPage',
                                params: {
                                    id: '0',
                                    school_id: prg.university?._id,
                                    program_id: prg._id,
                                },
                            }"
                            >Apply</router-link
                        >
                    </h6>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { toCurrency, markUpApplicationFee, formatProgramDuration } from '@/utils/constants';
    export default {
      name: 'ProgramCard',
      props: ['prg'],
      methods: {
        toCurrency,
        markUpApplicationFee,
        formatProgramDuration(months) {
            return formatProgramDuration(months);
        }
      }
    }
    </script>
    <style scoped>
    .title {
        color: #6a6a6a;
        font-size: 12px;
    }
    .boldText {
        font-weight: 400;
    }
    
    .programs-container {
        overflow-y: auto;
        overflow-x: hidden;
    }
    
    .border-right-button{
        border-right: 2px solid lightgray;
    }
    
    .myCard .card-header, .eachProgram .card-footer{
        background: rgb(0 0 0 /5%) !important;
    }
    </style>