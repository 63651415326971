const Colors = {
    primaryColor: '#5a189a',
    whiteColor: 'white',
};

const API_URL = window.location.protocol + '//devapi.schooliply.com/v1/'; // 'http://localhost:3001/v1/'; //  window.location.protocol + '//devapi.schooliply.com/v1/'; //              
const API_URL_STUDENT = API_URL + 'user/'; // 'http://localhost:3001/v1/user/' //
const API_URL_ADMIN = API_URL + 'admin/'; // 'http://localhost:3001/v1/user/' //
const API_URL_SCOUT = API_URL + 'scout/';

const about =
    "<p>In 1957, the University of Waterloo opened its doors to 74 engineering students with co-operative education as its cornerstone. Today, with more than 42,000+ students attending annually, Waterloo is #1 in Canada for experiential learning and employer-student connections. With a global network spanning more than 220,000 alumni in 151 countries, Waterloo attracts world-class scholars including a Nobel Laureate, leads in providing work-integrated learning opportunities with 7,100+ active co-op employers and fosters an entrepreneurial spirit that’s created 5,000+ jobs through Velocity alone, Canada’s most productive startup incubator by private investment. Today, the University of Waterloo continues to spur innovation to solve problems on a global scale.</p><p>As one of Canada’s top innovation universities, the University of Waterloo offers programs in business, engineering, health, humanities and social sciences, the environment, math, and science. Students will alternate school and paid work terms with the University of Waterloo's network of employers, including many of the world's top brands. Students will gain up to two years of relevant experience before starting their career. Many graduates also pursue further education such as law, medicine, business, or specialized master’s programs on campus or at other top universities around the world.</p>";

const features = [
    {
        header: 'Post Graduation Work Permit',
        body: 'In 1957, the University of Waterloo opened its doors to 74 engineering students with co-operative education as its cornerstone. Today, with more than.',
    },
    {
        header: 'Conditional Admission Offer',
        body: 'In 1957, the University of Waterloo opened its doors to 74 engineering students with co-operative education as its cornerstone. Today, with more than.',
    },
    {
        header: 'Internship opportunities',
        body: 'In 1957, the University of Waterloo opened its doors to 74 engineering students with co-operative education as its cornerstone. Today, with more than.',
    },
    {
        header: 'Accomodation',
        body: 'In 1957, the University of Waterloo opened its doors to 74 engineering students with co-operative education as its cornerstone. Today, with more than.',
    },
];

const programDesc =
    '<p>Honours Arts and Business is where the things students are passionate about intersect with business. Students can pick one of 23 majors — a subject they are curious about or something they have always loved — then layer on business classes that teach them the practical skills employers look for. If students would like their degree to be even more customized, they can add on a second honours major or a minor. Students can choose the regular system of study and finish in four years, or add 20 months of serious work experience to their résumés through co-op.</p>';

const admissionRequirement =
    '<div>' +
    '<ul>' +
    '<li>Minimum Level of Education Required: Grade 12 / High School</li>' +
    '<li>Min GPA: 85.0%</li>' +
    '<li>Min GPA: 85.0%</li>' +
    '<li>English Proficiency Requirements:' +
    '<div class="mt-3">For direct admission, applicant must be from an English speaking country or meet either of the following English test scores:</div>' +
    '<ul>' +
    '<li>Min TOEFL iBT: 90 (Min Speaking: 25.0, Min Writing: 25.0)</li>' +
    '<li>Min IELTS overall: 6.5 (Min Listening: 6.0, Min Reading: 6.0, Min Speaking: 6.5, Min Writing: 6.5)</li>' +
    '<li>Min Duolingo English Test Score: 120</li>' +
    '<li>Min IELTS overall: 5.5 (Min Writing: 5)</li>' +
    '</ul>' +
    '</li>' +
    '</ul>' +
    '</div>';

const programs = [
    {
        _id: 1,
        name: 'First Year Pathway in Psychology - Continue to Bachelor of Science (Honours) - Psychology - Clinical Psychology',
        program_type: '4-Year Bachelors Degree',
        application_fee: '$100',
        tuition_fee: '$20,000',
        program_desc: programDesc,
        admission_requirement: admissionRequirement,
    },
    {
        _id: 2,
        name: 'First Year Pathway in Psychology - Continue to Bachelor of Science (Honours) - Psychology - Clinical Psychology',
        program_type: '4-Year Bachelors Degree',
        application_fee: '$100',
        tuition_fee: '$20,000',
        program_desc: programDesc,
        admission_requirement: admissionRequirement,
    },
];

const Schools = [
    {
        _id: 1,
        name: 'University of Waterloo',
        logo: 'https://uwaterloo.ca/brand/sites/ca.brand/files/styles/body-500px-wide/public/uploads/images/universityofwaterloo_logo_horiz_rgb_1.jpg',
        location: 'Ontario, Canada',
        about: about,
        features,
        programs,
        school_type: 'Univeristy',
        programs_offered: 'BSc, MSc, PhD',
        available_courses: 32,
        tuition_range: '$20,000 - $30,000',
        application_fee: '$100',
    },
    {
        _id: 2,
        name: 'University of Calgary',
        logo: 'https://uwaterloo.ca/brand/sites/ca.brand/files/styles/body-500px-wide/public/uploads/images/universityofwaterloo_logo_horiz_rgb_1.jpg',
        location: 'Alberta, Canada',
        school_type: 'Univeristy',
        about: about,
        features,
        programs,
        programs_offered: 'BSc, MSc, PhD',
        available_courses: 20,
        tuition_range: '$10,000 - $15,000',
        application_fee: '$100',
    },
    {
        _id: 3,
        name: 'Conestoga College',
        logo: 'https://upload.wikimedia.org/wikipedia/commons/3/34/Conestoga_College_logo.png',
        location: 'Ontario, Canada',
        school_type: 'College',
        about: about,
        features,
        programs,
        programs_offered: 'Certificate, Diploma',
        available_courses: 32,
        tuition_range: '$5,000 - $10,000',
        application_fee: '$100',
    },
];

const Serialize = function (obj) {
    var str = [];
    for (var p in obj) {
        if (obj[p]?.trim() !== '') {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
    }
    return str.join('&');
};

const toCurrency = function (val) {
    return val?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const markUpApplicationFee = function (val) {
    if(val && val != 0) {
        return Number((val * 1.065).toFixed(2))
    }
    return val;
};



const calcTracker = function (app) {
    let level = ''
    if (app.application_tracker != null) {
      level = app.application_tracker
    } else {
      if (app.is_submitted && app.payment?.amount_to_pay === app.payment?.amount_paid) {
        level = 'Application Received'
      }
    }
    return level
  };

  const formatProgramDuration = function (months) {
    let years = Math.floor(months/12);
    let yearsSpell = years > 1 ? 'yrs' : 'yr';
    let monthsRemain = months % 12;
    let monthsRemainSpell = monthsRemain > 1 ? 'months' : 'month';
    return `${years}${yearsSpell} ${monthsRemain}${monthsRemainSpell}`
}

const applicationTracker = [
    {
        dropdown: true,
        progress: 1,
        status: 'Application Received',
        desc: 'We have received your application',
    },
    {
        dropdown: true,
        progress: 2,
        status: 'Schooliply Review',
        desc: 'We are reviewing your application',
    },
    {
        dropdown: true,
        progress: 3,
        status: 'Application Submitted',
        desc: 'Your application has been submitted to the school',
    },
];

const monthsWithNumber = {
    "January": 1,
    "February": 2,
    "March": 3,
    "April": 4,
    "May": 5,
    "June": 6,
    "July": 7,
    "August": 8,
    "September": 9,
    "October": 10,
    "November": 11,
    "December": 12
}

const FLUTTER_PUBLIC_KEY = "FLWPUBK_TEST-9e42b2432d331215af343eaa25682ebb-X";

const checkCanApply = function (deadline, totalSessions) {
    let disabled = false;
    /*let currentMonth = new Date().toLocaleString('en', {
        month: 'long',
    });*/
    let currentMonthDigit = new Date().getMonth() + 1; // monthsWithNumber[currentMonth];
    let deadlineDigit = monthsWithNumber[deadline];
    if (
        deadline &&
        deadlineDigit &&
        currentMonthDigit &&
        totalSessions > 1
    ) {
        if (deadlineDigit <= monthsWithNumber['May']) {
            // months before and May
            disabled =
                currentMonthDigit >= monthsWithNumber['August'] || 
                currentMonthDigit < deadlineDigit
                    ? false
                    : true;
        } else if (deadlineDigit > monthsWithNumber['May'] && deadlineDigit <= monthsWithNumber['September']) {
            // months from June to September
            disabled = (currentMonthDigit == 11 || currentMonthDigit < deadlineDigit) ? false : true;
        } else {
            // months after September
            disabled = currentMonthDigit < deadlineDigit ? false : true;
        }
    }

    return disabled;
}

export {
    Colors,
    API_URL,
    API_URL_STUDENT,
    API_URL_ADMIN,
    API_URL_SCOUT,
    Schools,
    Serialize,
    toCurrency,
    markUpApplicationFee,
    calcTracker,
    applicationTracker,
    monthsWithNumber,
    FLUTTER_PUBLIC_KEY,
    formatProgramDuration,
    checkCanApply
};
